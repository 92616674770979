import React, { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3 } from '../hooks/useWeb3';
import styled, { css, createGlobalStyle } from 'styled-components';
import ReactDOM from 'react-dom';
import { getContract } from '../utils/contract';
import TradeList from '../components/TradeList';
import TradeModal from '../components/TradeModal';
import DepositModal from '../components/DepositModal';
import WithdrawModal from '../components/WithdrawModal';
import { updateOfferOnDB } from '../hooks/firebaseConfig';
import { ToastContainer } from 'react-toastify'; // move to App.tsx
import 'react-toastify/dist/ReactToastify.css'; // move to App.tsx
import { notifyUser } from '../utils/notifier';
import { parseBigNumberToString } from '../utils/formatter';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto Mono', monospace;
  }
`;

interface ButtonProps {
  color: string;
  $position?: 'left' | 'right';
  $variation?: 'normal' | 'inverted';
}

interface BalanceContainerProps {
  balance: string;
}

interface ToastProps {
  toastRoot: HTMLElement | null;
}

interface ClickableImageProps {
  src: string;
  alt: string;
  link?: string;
  clickable: boolean;
}

const Container = styled.div<{ $blur: 'blur' | undefined }>`
  ${({ $blur }) => $blur === 'blur' ? `
  filter: blur(5px);
  pointer-events: none;
  opacity: 0.7;
  ` : ''}
  text-align: center;
  padding: 20px;
  background-color: #000000;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`;

const trapezoidStyles = css<ButtonProps>`
  display: inline-block;
  padding: 8px 20px;
  margin: 10px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${(props) => props.color};  
  transition: transform 0.4s ease;
  clip-path: ${(props) => (props.$variation === 'inverted' ? 'polygon(0% 0%, 85% 0%, 100% 100%, 15% 100%)' : 'polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%)')}; 
  border: 2px solid #000;
  min-width: 125px;
  cursor: pointer;
`;

const Button = styled.button<ButtonProps>`
  ${trapezoidStyles};
  font-family: 'Roboto Mono', monospace;
  font-size: 18px;
  border: none;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #3C99DC;
  }
`;

const BalanceContainerWrapper = styled.div`
  position: absolute;
  padding: 0px 20px;
  margin: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  top: 10px;
  left: 10px;
  color: #fff;
  font-weight: bold;
  background-color: #3C99DC;
  display: inline-block;
  cursor: default;
  transition: transform 0.4s ease;
  min-width: 125px;
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
`;

const ModalButton = styled(Button) <ButtonProps>`
  position: absolute;
  ${(props) => props.$position === 'left' && 'left: 10px;'}
  ${(props) => props.$position === 'right' && 'right: 10px;'}
`;

const CentralImage = styled.img.attrs<{ clickable: boolean }>(() => ({
  // Não passa 'clickable' para o DOM
  clickable: undefined
})) <{ clickable: boolean }>`
  max-width: 100%;
  height: auto;
  margin: 20px auto;
  display: block;
  margin-bottom: 3%;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

const BalanceContainer: React.FC<BalanceContainerProps> = ({ balance }) => {
  return ReactDOM.createPortal(
    <BalanceContainerWrapper>
      <p>{balance} USDC</p>
    </BalanceContainerWrapper>,
    document.body
  );
};

const Toast: React.FC<ToastProps> = ({ toastRoot }) => {
  return ReactDOM.createPortal(
    <ToastContainer />,
    toastRoot!
  );
};

const ClickableImage: React.FC<ClickableImageProps> = ({ src, alt, link, clickable }) => (
  clickable ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <CentralImage src={src} title={alt} clickable={clickable} />
    </a>
  ) : (
    <CentralImage src={src} alt={alt} clickable={clickable} />
  )
);

interface Limits {
  opennedTradesLimit: number;
  maxTradeAmountLimit: BigNumber;
  collateralPercent: number;
  feePercent: number;
}

interface Counts {
  tradeCount: BigNumber;
  buyCount: BigNumber;
  sellCount: BigNumber;
};

interface User {
  user: string;
  username: string;
  limits: Limits;
  opennedTradesCount: number;
  lastTradeDay: BigNumber;
  counts: Counts;
  status: boolean;
}

interface Trade {
  user1: User;
  user2: User;
  tradeId: BigNumber;
  sellTrade: boolean;
  amount: BigNumber;
  price: BigNumber;
  fiatCurrency: string;
  acceptedTimestamp: BigNumber;
}

const zero = BigNumber.from(0);

const defaultUser: User = {
  user: ethers.constants.AddressZero,
  username: '',
  limits: {
    opennedTradesLimit: 0,
    maxTradeAmountLimit: zero,
    collateralPercent: 0,
    feePercent: 0,
  },
  opennedTradesCount: 0,
  lastTradeDay: zero,
  counts: { tradeCount: zero, buyCount: zero, sellCount: zero },
  status: false
}

const Home: React.FC = () => {
  const { active, account, library } = useWeb3();
  const [trades, setTrades] = useState<Trade[]>([]);
  const [isJudge, setIsJudge] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [user, setUser] = useState<User>(defaultUser);
  const [tradeModalOpen, setTradeModalOpen] = useState(false);
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [debugMessage, setDebugMessage] = useState<string | null>(null);
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from('0'));
  const [sellTrade, setSellTrade] = useState(true);
  const [isTradeListModalOpen, setIsTradeListModalOpen] = useState(false);

  const minTradeAmountLimit = BigNumber.from('10000000'); // 10$

  const collateralMinAmount = minTradeAmountLimit.mul(user.limits.collateralPercent).div(10000);
  const feeMinAmount = minTradeAmountLimit.mul(user.limits.feePercent).div(10000);
  const totalMinimum = minTradeAmountLimit.add(collateralMinAmount).add(feeMinAmount);

  const closeAllModals = () => {
    setTradeModalOpen(false);
    setDepositModalOpen(false);
    setWithdrawModalOpen(false);
  };

  const handleTradeListModalStateChange = (isOpen: boolean) => {
    setIsTradeListModalOpen(isOpen);
    if (isOpen) closeAllModals();
  };

  const handleTradesBatch = (tradesBatch: Trade[]) => {
    setTrades(prevTrades => [...prevTrades, ...tradesBatch]);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (active && account) {
        const contract = getContract(library);
        try {
          const u: User = await contract.getUser(account);
          setUser(u);

          //setDebugMessage(JSON.stringify(u, null, 2));

          const judge = await contract.isJudge();
          setIsJudge(judge);

          const owner = await contract.isOwner();
          setIsOwner(owner);

          const userBalance = await contract.getUserBalance(account);
          setBalance(userBalance);

          const allTrades = await contract.getAllTrades();
          handleTradesBatch(allTrades);

        } catch (error: any) {
          setDebugMessage(error.data ? error.data.message : error.message);
        }
      }
    };

    fetchData();
  }, [active, account, library]);

  useEffect(() => {
    if (active && account) {
      const contract = getContract(library);

      const handleDepositTokens = (user: User, amount: BigNumber) => {
        if (user.user.toLowerCase() === account.toLowerCase()) {
          setBalance(prevBalance => amount.add(prevBalance));
          notifyUser(user, 'deposit', { amount });
        }
      };

      const handleWithdrawTokens = (user: User, amount: BigNumber) => {
        if (user.user.toLowerCase() === account.toLowerCase()) {
          setBalance(prevBalance => prevBalance.sub(amount));
          notifyUser(user, 'withdraw', { amount });
        }
      };

      const handleTradeInitiated = (trade: Trade) => {
        updateOfferOnDB(trade.tradeId.toString(), true);
        setTrades(prevTrades => [...prevTrades, trade]);

        const feeAmount = trade.amount.mul(trade.user1.limits.feePercent).div(10000);
        const collateralAmount = trade.amount.mul(trade.user1.limits.collateralPercent).div(10000);

        if (trade.user1.user.toLowerCase() === account.toLowerCase()) {
          setBalance(prevBalance => trade.sellTrade ? prevBalance.sub(trade.amount).sub(feeAmount).sub(collateralAmount) : prevBalance.sub(feeAmount).sub(collateralAmount));
          notifyUser(trade.user1, 'tradeInitiated', { amount: trade.amount, price: trade.price, fiatCurrency: trade.fiatCurrency, tradeType: trade.sellTrade ? 'venda' : 'compra' });
        }
      };

      const handleTradeAccepted = (trade: Trade) => {
        setTrades(prevTrades => prevTrades.map(t =>
          t.tradeId.toString() === trade.tradeId.toString()
            ? { ...t, user2: trade.user2 }
            : t
        ));
        if (trade.user1.user.toLowerCase() === account.toLowerCase()) {
          notifyUser(trade.user1, 'tradeAccepted', { amount: trade.amount, price: trade.price, fiatCurrency: trade.fiatCurrency, tradeType: trade.sellTrade ? 'venda' : 'compra', });
        } else if (trade.user2.user.toLowerCase() === account.toLowerCase()) {
          const feeAmount = trade.amount.mul(trade.user2.limits.feePercent).div(10000);
          const collateralAmount = trade.amount.mul(trade.user2.limits.collateralPercent).div(10000);
          setBalance(prevBalance => trade.sellTrade ? prevBalance.sub(feeAmount).sub(collateralAmount) : prevBalance.sub(trade.amount).sub(feeAmount).sub(collateralAmount));
        }
      };

      const handleTradeCancelled = (trade: Trade) => {
        setTrades(prevTrades => prevTrades.filter(t => t.tradeId.toString() !== trade.tradeId.toString()));
        if (trade.user1.user.toLowerCase() === account.toLowerCase()) {
          const feeAmount = trade.amount.mul(trade.user1.limits.feePercent).div(10000);
          const collateralAmount = trade.amount.mul(trade.user1.limits.collateralPercent).div(10000);
          setBalance(prevBalance => trade.sellTrade ? prevBalance.add(trade.amount).add(feeAmount).add(collateralAmount) : prevBalance.add(feeAmount).add(collateralAmount));
          notifyUser(trade.user1, 'tradeCancelled', { tradeType: trade.sellTrade ? 'venda' : 'compra' });
        }
      };

      const handleTradeCompleted = (trade: Trade) => {
        setTrades(prevTrades => prevTrades.filter(t => t.tradeId.toString() !== trade.tradeId.toString()));
        const user1CollateralAmount = trade.amount.mul(trade.user1.limits.collateralPercent).div(10000);
        const user2CollateralAmount = trade.amount.mul(trade.user2.limits.collateralPercent).div(10000);
        if (trade.user1.user.toLowerCase() === account.toLowerCase()) {
          notifyUser(trade.user1, 'tradeCompleted', { amount: trade.amount, price: trade.price, fiatCurrency: trade.fiatCurrency, tradeType: trade.sellTrade ? 'venda' : 'compra', invertTradeType: false });
          setBalance(prevBalance => trade.sellTrade ? prevBalance.add(user1CollateralAmount) : prevBalance.add(trade.amount).add(user1CollateralAmount));
        } else if (trade.user2.user.toLowerCase() === account.toLowerCase()) {
          notifyUser(trade.user2, 'tradeCompleted', { amount: trade.amount, price: trade.price, fiatCurrency: trade.fiatCurrency, tradeType: trade.sellTrade ? 'venda' : 'compra', invertTradeType: true });
          setBalance(prevBalance => trade.sellTrade ? prevBalance.add(trade.amount).add(user2CollateralAmount) : prevBalance.add(user2CollateralAmount));
        }
      };

      const handleDisputeCompleted = (winner: string, trade: Trade) => {
        setTrades(prevTrades =>
          prevTrades.filter(t => t.tradeId.toString() !== trade.tradeId.toString())
        );

        const user1CollateralAmount = trade.amount.mul(trade.user1.limits.collateralPercent).div(10000);
        const user2CollateralAmount = trade.amount.mul(trade.user2.limits.collateralPercent).div(10000);

        if (winner === ethers.constants.AddressZero) {
          if (trade.user1.user.toLowerCase() === account.toLowerCase()) {
            notifyUser(trade.user1, 'disputeNobody', { tradeType: trade.sellTrade ? 'venda' : 'compra', tradeId: trade.tradeId });
            setBalance(prevBalance => trade.sellTrade ? prevBalance.add(trade.amount).add(user1CollateralAmount) : prevBalance.add(user1CollateralAmount));
          } else if (trade.user2.user.toLowerCase() === account.toLowerCase()) {
            notifyUser(trade.user2, 'disputeNobody', { tradeType: trade.sellTrade ? 'venda' : 'compra', tradeId: trade.tradeId });
            setBalance(prevBalance => trade.sellTrade ? prevBalance.add(user2CollateralAmount) : prevBalance.add(trade.amount).add(user2CollateralAmount));
          }
        } else {
          const user1IsWinner = trade.user1.user.toLowerCase() === winner.toLowerCase();
          const user2IsWinner = trade.user2.user.toLowerCase() === winner.toLowerCase();

          if (user1IsWinner) {
            if (trade.user1.user.toLowerCase() === account.toLowerCase()) {
              notifyUser(trade.user1, 'disputeWon', {
                tradeType: trade.sellTrade ? 'venda' : 'compra', tradeId: trade.tradeId
              });
            } else if (trade.user2.user.toLowerCase() === account.toLowerCase()) {
              notifyUser(trade.user2, 'disputeLost', { tradeType: trade.sellTrade ? 'venda' : 'compra', tradeId: trade.tradeId });
            }
          } else if (user2IsWinner) {
            if (trade.user2.user.toLowerCase() === account.toLowerCase()) {
              notifyUser(trade.user2, 'disputeWon', { tradeType: trade.sellTrade ? 'venda' : 'compra', tradeId: trade.tradeId });
            } else if (trade.user1.user.toLowerCase() === account.toLowerCase()) {
              notifyUser(trade.user1, 'disputeLost', { tradeType: trade.sellTrade ? 'venda' : 'compra', tradeId: trade.tradeId });
            }
          }

          if (winner.toLowerCase() === account.toLowerCase()) {
            setBalance(prevBalance => prevBalance.add(trade.amount).add(user1CollateralAmount).add(user2CollateralAmount)
            );
          }
        }
      };

      const handleWhitelistedUser = (user: User) => {
        if (user.user.toLowerCase() === account.toLowerCase()) {
          setUser(user);
          notifyUser(user, 'whitelistedUser');
        }
      };

      const handleUserLimitsUpdated = (user: User) => {
        if (user.user.toLowerCase() === account.toLowerCase()) {
          setUser(user);
          notifyUser(user, 'userLimitsUpdated');
        }
      }

      const handleUserAccessUpdated = (user: User) => {
        if (user.user.toLowerCase() === account.toLowerCase()) {
          setUser(user);
          notifyUser(user, 'userAccessUpdated', { status: user.status });
        }
      }

      contract.on('Deposit', handleDepositTokens);
      contract.on('Withdraw', handleWithdrawTokens);
      contract.on('TradeInitiated', handleTradeInitiated);
      contract.on('TradeAccepted', handleTradeAccepted);
      contract.on('TradeCancelled', handleTradeCancelled);
      contract.on('TradeCompleted', handleTradeCompleted);
      contract.on('DisputeCompleted', handleDisputeCompleted);
      contract.on('WhitelistedUser', handleWhitelistedUser);
      contract.on('UserLimitsUpdated', handleUserLimitsUpdated);
      contract.on('UserAccessUpdated', handleUserAccessUpdated);

      return () => {
        contract.off('Deposit', handleDepositTokens);
        contract.off('Withdraw', handleWithdrawTokens);
        contract.off('TradeInitiated', handleTradeInitiated);
        contract.off('TradeAccepted', handleTradeAccepted);
        contract.off('TradeCancelled', handleTradeCancelled);
        contract.off('DisputeCompleted', handleDisputeCompleted);
        contract.off('TradeCompleted', handleTradeCompleted);
        contract.off('WhitelistedUser', handleWhitelistedUser);
        contract.off('UserLimitsUpdated', handleUserLimitsUpdated);
        contract.off('UserAccessUpdated', handleUserAccessUpdated);
      };
    }
  }, [active, account, library]);

  const toastRoot = document.getElementById('toast-root');

  return (
    <Container $blur={tradeModalOpen || depositModalOpen || withdrawModalOpen || isTradeListModalOpen ? "blur" : undefined}>
      <GlobalStyle />
      <ClickableImage
        clickable={!isOwner && !isJudge && !balance.gt(0) && !user.status}
        src="/banner-coleta.webp"
        alt="Clique e seja membro!"
        link="https://www.youtube.com/channel/UCh_m7yu_7JnZkXUNPjfijng/join"
      />
      <Toast toastRoot={toastRoot} />
      {active && (
        <>
          <>
            {(balance.gt(0) || isJudge || user.status) && (
              <BalanceContainer balance={parseBigNumberToString(balance)} />
            )}
            {(balance.gte(totalMinimum) && user.status) && (
              <>
                <ButtonContainer>
                  <ModalButton color="#0FB10F" onClick={() => { closeAllModals(); setSellTrade(false); setTradeModalOpen(true); }} $position="right" $variation="inverted">COMPRAR</ModalButton>
                  <ModalButton color="#D22D2D" onClick={() => { closeAllModals(); setSellTrade(true); setTradeModalOpen(true); }} $position="left">VENDER</ModalButton>
                </ButtonContainer>
                <TradeModal isOpen={tradeModalOpen} onClose={() => setTradeModalOpen(false)} balance={balance} user={user} sellTrade={sellTrade} />
              </>
            )}
            {user.status && !isJudge && (
              <>
                <Button color="#0FB10F" onClick={() => { closeAllModals(); setDepositModalOpen(true) }}>DEPOSITAR</Button>
                <DepositModal isOpen={depositModalOpen} onClose={() => setDepositModalOpen(false)} />
              </>
            )}
            {balance.gt(0) && (
              <>
                <Button color="#D22D2D" onClick={() => { closeAllModals(); setWithdrawModalOpen(true) }}>SACAR</Button>
                <WithdrawModal isOpen={withdrawModalOpen} onClose={() => setWithdrawModalOpen(false)} balance={balance} />
              </>
            )}
          </>
          {isOwner && (
            <Button color="#0FB10F">GERENCIAR</Button>
          )}
          {debugMessage && <p style={{ color: 'blue' }}>{debugMessage}</p>}
          <>
            {(isOwner || isJudge || user.user === account) && (
              <TradeList trades={trades} account={account ?? null} isJudge={isJudge} isOwner={isOwner} userBalance={balance} user={user} onModalStateChange={handleTradeListModalStateChange} />
            )}
          </>
        </>
      )}
    </Container>
  );
};

export default Home;
